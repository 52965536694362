<template>
  <CRow>
    <CCol :col="{ size: 12, order: 2 }" :lg="{ size: 9, order: 1 }">
      <CCard>
        <CCardHeader class="d-flex align-items-center">
          <TMessage v-if="!titleEditing" content="Detail" bold>
            <template>
              <div class="d-flex">
                <CBadge color="info" class="d-flex my-auto">
                  #<TMessageText :value="detail.id" />
                </CBadge>
                <TMessageText :value="detail.title" class="h5 ml-1 my-auto" />
              </div>
            </template>
          </TMessage>
          <div v-else class="d-flex w-100 mr-2">
            <TInputText
              :value="detail.title"
              class="w-100"
              @update:value="titleUpdate = $event"
            />
            <TButtonSave
              @click="
                updatePost('title', titleUpdate).finally(() => {
                  titleEditing = false;
                })
              "
            />
            <TButtonClose @click="titleEditing = false" />
          </div>
          <TSpinner :loading="loading" />
          <TButtonEdit
            v-if="!titleEditing"
            variant="outline"
            class="ml-auto"
            @click="titleEditing = true"
          />
        </CCardHeader>
        <CCardBody>
          <CCol col="12">
            <div class="d-flex">
              <TMessage content="Content" class="mb-3" uppercase>
                <template #suffix>:</template>
              </TMessage>
              <div class="ml-auto my-auto">
                <TButtonEdit
                  v-if="!isDescriptionChange"
                  variant="outline"
                  @click="isDescriptionChange = !isDescriptionChange"
                />
                <TButtonSave
                  v-if="isDescriptionChange"
                  :options="{ disabled: loading }"
                  class="ml-1 btn-outline-info"
                  variant="outline"
                  @click="updatePost('content', content)"
                />
                <TButtonClose
                  v-if="isDescriptionChange"
                  variant="outline"
                  class="ml-1 btn-outline-danger"
                  @click="isDescriptionChange = !isDescriptionChange"
                />
              </div>
            </div>
            <quillEditor
              v-model="content"
              class="editor"
              :disabled="!isDescriptionChange"
            >
            </quillEditor>
          </CCol>
        </CCardBody>
      </CCard>

      <CCard class="w-100 ml- mb-2" borderColor="">
        <CCardHeader class="d-flex" color="inf" textColor="whit">
          <TMessage content="Description" bold />
        </CCardHeader>
        <CCardBody>
          <TMessageTextarea
            :value="detail.description"
            @change="updatePost('description', $event)"
            editable
          />
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :col="{ size: 12, order: 1 }" :lg="{ size: 3, order: 2 }" :order="{}">
      <CCard>
        <CCardHeader class="d-flex align-items-center p-3">
          <TMessage content="Info" bold />
        </CCardHeader>
        <CCardBody>
          <TInputFile
            accept="image/*"
            :value.sync="files"
            :multiple="!files.length ? false : true"
            class="w-100"
            theme="default"
            label="Featured image"
            :maxFiles="1"
            :meta="false"
            @update:value="updateImage"
          />
          <TMessage content="Creator" class="mt-3" bold />
          <CBadge color="info">
            {{ detail.creator ? detail.creator.name : detail.creator_id }}
          </CBadge>
          <TMessage content="Created at" class="mt-3 mb-1" bold />
          <TMessageDateTime :content="detail.created_at" />
          <TMessage content="Publish" class="mt-3 mb-1" bold />
          <TSwitch
            :checked="detail.publish ? true : false"
            :disabled="loading"
            @change="updatePost('publish', $event ? 1 : 0)"
          />
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader class="d-flex align-items-center p-3">
          <TMessage content="Category" bold />
          <TButtonAdd
            class="ml-auto"
            variant="outline"
            @click="showModalAddCategory = true"
          />
        </CCardHeader>
        <CCardBody>
          <div
            v-if="detail.categories && detail.categories.length"
            class="d-flex flex-wrap mt-2 mb-3"
          >
            <div
              color="primary"
              class="d-flex align-items-center rounded p-2 mb-1 border-info w-100"
              v-for="cat in detail.categories"
              :key="cat.id"
            >
              <strong> {{ cat.name ? cat.name : cat.id }}</strong>
              <TButtonRemove
                class="ml-auto"
                @click="updateCategory(cat.id, 'remove')"
              />
            </div>
          </div>
          <TMessageNotFound v-else />
        </CCardBody>
        <AddPostCategory
          :show.sync="showModalAddCategory"
          @update:category="updateCategory($event, 'add')"
        />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapGetters } from "vuex";
import AddPostCategory from "./AddPostCategory.vue";
export default {
  components: {
    quillEditor,
    AddPostCategory,
  },
  data() {
    return {
      files: [],
      titleUpdate: "",
      titleEditing: false,
      content: "",
      isDescriptionChange: false,
      showModalAddCategory: false,
    };
  },
  watch: {
    "detail.content": {
      immediate: true,
      handler(v) {
        this.content = v;
      },
    },
    "detail.cover_url": {
      immediate: false,
      handler(value) {
        if (value)
          this.files = [
            {
              id: 1,
              name: this.detail.cover_path,
              size: 0,
              type: "image/jpeg",
              ext: "jpg",
              url: value,
            },
          ];
        else this.files = [];
      },
    },
  },
  created() {
    if (this.id) {
      this.$store.dispatch("helper.posts.detail.fetch", this.id);
    }
  },
  destroyed() {
    this.$store.commit("helper.posts.detail.purge");
  },
  computed: {
    ...mapGetters({
      detail: "helper.posts.detail",
      loading: "helper.posts.detail.loading",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    fetchDetail() {
      this.$store.dispatch("helper.posts.detail.refresh");
    },
    updatePost(field, value) {
      return this.$store.dispatch("helper.posts.detail.update", {
        title: this.detail.title,
        content: this.detail.content,
        description: this.detail.description,
        [field]: value,
      });
    },
    updateImage(v) {
      this.files = v;
      if (v.length) this.updatePost("cover_file", v[0].file);
    },
    updateCategory(id, type) {
      let list = [...this.detail.categories];
      let data = {
        title: this.detail.title,
        content: this.detail.content,
        description: this.detail.description,
      };
      const itemIndex = list.findIndex((x) => x.id == id);
      if (type == "remove") {
        list.splice(itemIndex, 1);
      }

      if (type == "add" && itemIndex == -1) {
        list.push({ id: id });
      }
      if (type == "add" && itemIndex != -1) return;

      list.forEach((cat, index) => {
        data = { ...data, [`categories[${index}]`]: cat.id };
      });
      if (!list.length) data = { ...data, categories: [] };
      this.$store.dispatch("helper.posts.detail.update", data).then(() => {
        this.fetchDetail();
      });
    },
  },
};
</script>

<style scoped>
* >>> textarea.form-control {
  height: 80px;
}
* >>> .ql-container {
  min-height: 200px;
}
</style>
