<template>
  <TModal
    title="Add category"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="add"
    :creating="category_id ? false : true"
  >
    <template #actions>
      <TButton
        content="Create"
        :options="{ disabled: !category_id }"
        icon="cil-plus"
        @click="add"
      />
    </template>
    <SSelectPostCategory
      label="Category"
      :prepend="[]"
      :value.sync="category_id"
      @update:value="category_id = $event"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      category_id: "",
    };
  },
  methods: {
    add() {
      this.$emit("update:category", this.category_id);
      this.$emit("update:show", false);
      this.category_id = "";
    },
  },
};
</script>
